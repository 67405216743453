var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('PageTop1'), _c('PageTop'), _c('div', {
    staticClass: "v2-g-w1200"
  }, [_c('img', {
    staticStyle: {
      "width": "100%",
      "max-height": "400px"
    },
    attrs: {
      "src": _vm.pcImg
    }
  }), _vm._m(0), _c('ProductList', {
    attrs: {
      "list": _vm.list,
      "pageInfo": _vm.pageInfo,
      "hideSearch": true
    },
    on: {
      "getdata": _vm.getDataByPage,
      "getGoodsList": _vm.getData
    }
  })], 1), _c('PageBottom')], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "title v2-g-flex-row v2-g-flex-space-between"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/zuoxian.png")
    }
  })]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/dh_lingxing.png")
    }
  })]), _c('div', [_c('span', [_vm._v("专 / 题 / 活 / 动")])]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/dh_lingxing.png")
    }
  })]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/zuoxian.png")
    }
  })])]);
}];
export { render, staticRenderFns };